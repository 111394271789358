import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { AdminInteractionObjectType, AdminInteractionReason, ShippingProviderType } from "@reshopper/admin-client";
import { useEffect, useState } from "react";
import { adminClient } from "../../api/admin/AdminClient";


export default function AdminInteractionDialog(props: {
	objectId: string | undefined,
	adminInteractionObjectType: AdminInteractionObjectType | undefined,
	transactionShippingProviderType?: ShippingProviderType
}) {

	const [validTransactionInteractionReasons, setValidTransactionInteractionReasons] = useState<AdminInteractionReason[]>();
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	useEffect(() => {
		const fetchRecentInteraction = async () => {
			var response = await adminClient().adminInteractionsRecentGet({objectId: props.objectId});
			if(response.adminInteractions.length === 0)
			{
				setIsDialogOpen(true);
			}
		}

		fetchRecentInteraction();
		
		const fetchValidTransactionInteractionReasons = async () => {
			var response = await adminClient().adminInteractionsValidTransactionReasonsGet();
			setValidTransactionInteractionReasons(response.adminInteractionReasons);
		}

		fetchValidTransactionInteractionReasons();
	}, [])

	const handleConfirm = async (event: React.ChangeEvent<{ value: unknown }>) => {
		await adminClient().adminInteractionsPost({
			body: {
				objectId: props.objectId,
				objectType: props.adminInteractionObjectType,
				reason: event.target.value as AdminInteractionReason,
				transactionData: props.transactionShippingProviderType && {shippingProviderType: props.transactionShippingProviderType}
			}
		});

		setIsDialogOpen(false);
	}

	if (!validTransactionInteractionReasons || validTransactionInteractionReasons?.length === 0) {
		return <></>;
	}

	return <Dialog open={isDialogOpen}>
		<DialogTitle>
			{`Select reason for interacting${props.adminInteractionObjectType && ` with ${props.adminInteractionObjectType}`}`}
		</DialogTitle>
		<DialogContent>
			<FormControl style={{minWidth: 120}}>
				<InputLabel>
					Reason
				</InputLabel>
				<Select
					fullWidth
					onChange={(event) => handleConfirm(event)}>
					{validTransactionInteractionReasons.map(adminInteractionReason => {
						return <MenuItem value={adminInteractionReason}>{adminInteractionReason}</MenuItem>
					})}
				</Select>
			</FormControl>
		</DialogContent>
		<DialogActions></DialogActions>
	</Dialog>
}