import CodeTextArea from "@components/admin/CodeTextArea";
import { Dialog, DialogContent, Typography } from "@material-ui/core";

export default function JsonModalTransactionComponent(props: {
  isModalOpen: boolean;
  closeModal: () => void;
  json: string;
  title: string;
}) {
  function generateTransactionJson() {
    if (!props.json)
      return;

    let prettyJson = props.json;
    return <CodeTextArea
      code={prettyJson}
      readonly
    />
  }

  return <Dialog
    fullWidth
    maxWidth="xl"
    open={props.isModalOpen}
    onClose={() => props.closeModal()}
  >
    <DialogContent>
      <Typography variant="subtitle2">{props.title}</Typography>
      {generateTransactionJson()}
    </DialogContent>
  </Dialog>

}
