import { adminClient } from "@api/admin/AdminClient";
import BoolNewLabelDialogComponent from "@components/admin/BookNewLabelDialog";
import InformationDialogComponent from "@components/admin/InformationDialogComponent";
import {
  ListItemIcon,
  MenuItem,
  Typography
} from "@material-ui/core";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import { AdminGetTransactionResponse, ITransactionLabelBooking } from "@reshopper/admin-client";
import { ShippingProviderType } from "@reshopper/web-client";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function BookNewLabelTransactionComponent(
  props: {
    transactionId: string,
    transaction: AdminGetTransactionResponse,
    onClose: () => void
  }
): JSX.Element {
  const [newBooking, setNewBooking] = useState<ITransactionLabelBooking | undefined>(undefined);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  function handleOpen() {
    props.onClose();
    setIsDialogOpen(true);
  }

  function action(transactionid: string, providerType: ShippingProviderType, pickUpPoint: string | undefined, weightUpTil: number, shouldCancelPrimaryBooking: boolean): Promise<void> {
    return performAction(async () =>
      setNewBooking(await adminClient().adminTransactionsBookAdditionalLabelPost(transactionid, {
        shippingProviderType: providerType,
        pickupPointId: pickUpPoint ?? undefined,
        weight: weightUpTil,
        shouldCancelPrimaryBooking
      })),
      "Failed to book shipping label",
      false
    );
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <FiberNewIcon />
      </ListItemIcon>
      Book new label
    </MenuItem>
    <BoolNewLabelDialogComponent
      performAction={action}
      objectId={props.transactionId}
      isOpen={isDialogOpen}
      setIsOpen={setIsDialogOpen}
      transaction={props.transaction} />
    <InformationDialogComponent
      isDialogOpen={newBooking !== undefined}
      onDismiss={() => {
        setNewBooking(undefined)
        window.location.reload();
      }}
      dialogTitle="Shipping label booked"
      dialogContent={() =>
        <>
          <Typography>Package Label: {(newBooking?.packageLabel || "")}</Typography>
          <Typography>Package Number: {(newBooking?.packageNumber || "")}</Typography>
          <Typography>Label: {(newBooking?.linkToLabelAsPdf || "")}</Typography>
        </>} />
  </>
}