import InternalLink from "@components/InternalLink";
import { Card, CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { AdminGetTransactionResponse, AdminTransactionLabelBookingResponse } from "@reshopper/admin-client";
import { navigateToExternalUrl } from "@utils/navigation";

interface Props {
    transaction: AdminGetTransactionResponse
}

export default function TransactionAdditionalLabelBookingsComponent(props: Props) {

    function renderTrackingLink(additionalLabelBooking: AdminTransactionLabelBookingResponse) {
        const isTrackingLinkAvailable =
            additionalLabelBooking.trackingUrl !== undefined ||
            additionalLabelBooking.packageNumber !== undefined;

        if (!isTrackingLinkAvailable) {
            return <TableCell>
                {additionalLabelBooking.packageNumber}
            </TableCell>
        }

        return <TableCell>
            <InternalLink
                onClick={() => navigateToExternalUrl(additionalLabelBooking.trackingUrl, true)}>
                {additionalLabelBooking.packageNumber}
            </InternalLink>
        </TableCell>
    }

    let additionalTransactionLabelBookings = props.transaction?.details?.shipping?.additionalTransactionLabelBookings;

    if (!additionalTransactionLabelBookings) {
        return <>
        </>;
    }
    return <>
        <Card style={{ marginTop: 16 }}>
            <CardContent>
                <Typography variant="h5">Additional Labels</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Package Label</TableCell>
                                <TableCell>Package Number</TableCell>
                                <TableCell>Provider</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {additionalTransactionLabelBookings.map(additionalTransactionLabelBooking => {
                                return <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>{additionalTransactionLabelBooking.packageLabel}</TableCell>
                                    <TableCell>{renderTrackingLink(additionalTransactionLabelBooking)}</TableCell>
                                    <TableCell>{additionalTransactionLabelBooking.shippingProviderType?.toString()}</TableCell>
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    </>;
}

