import { adminClient } from "@api/admin/AdminClient";
import EditValueDialogComponent from "@components/admin/EditValueDialogComponent";
import { Button } from "@material-ui/core";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function EditAdminNoteTransactionButton(props: {
  id: string;
  adminNote?: string;
}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
  }

  async function action(adminNote?: string) {
    adminNote = !adminNote ? undefined : adminNote;

    await performAction(
      () =>
        adminClient().adminEditTransactionNotePost({
          body: {
            transactionId: props.id,
            adminNote,
          },
        }),
      "Failed to edit admin note"
    );
  }

  return (
    <>
      <Button onClick={handleOpen} color="primary">
        Admin Note
      </Button>
      <EditValueDialogComponent
        isDialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSubmit={action}
        title={"Edit admin note"}
        defaultValue={props.adminNote}
      />
    </>
  );
}
