import { adminClient } from "@api/admin/AdminClient";
import JsonModalTransactionComponent from "@components/admin/transactions/details/actions/view/JsonModalTransactionDropdownMenuItem";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { useState } from "react";

export default function ViewTrackingJsonTransactionComponent(props: {id: string, onClose: () => void}): JSX.Element {
  const [trackingJson, setTrackingJson] = useState<string>()

  async function handleOpen() {
    props.onClose();
    
    const response = await adminClient().adminTransactionsTrackingAsJsonGet(props.id);
    setTrackingJson(response.result);
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <LocalShippingIcon />
      </ListItemIcon>
      View Tracking JSON
    </MenuItem>
    <JsonModalTransactionComponent
      isModalOpen={!!trackingJson}
      closeModal={() => setTrackingJson(undefined)}
      json={trackingJson || ""}
		title="Tracking JSON"
    />
  </>
}
