import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import CancelScheduleSendIcon from "@material-ui/icons/CancelScheduleSend";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function RetractPaymentReportTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <CancelScheduleSendIcon />
      </ListItemIcon>
                  Retract payment report
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminTransactionsRetractPaymentPost(props.id), 
        "Failed to retract payment report")}
      title="Confirm retracting payment report"
      description="Are you sure you want to retract payement report for this transaction?"
    />
  </>
}