import { Modal } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "flex",
      justifyContent: "center"
    },
    image: {
      display: "block",
      margin: 32
    }
  }),
);

export default function ImageModalComponent(props: {
  isModalOpen: boolean;
  onClose: () => void;
  imageUrl: string;
}) {
  const classes = useStyles();

  return <Modal
    open={props.isModalOpen}
    onClose={() => props.onClose()}
    className={classes.modal}
  >
    <img src={props.imageUrl} className={classes.image} alt="item" />
  </Modal>

}