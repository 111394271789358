import { adminClient } from "@api/admin/AdminClient";
import EditValueDialogComponent from "@components/admin/EditValueDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function MakeReshopperCommentTransactionComponent(props: {id: string, onClose: () => void}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  async function action(comment: string) {
    await performAction(
      () => adminClient().adminTransactionsCreateReshopperCommentPost(props.id, {comment}), 
      "Failed to make Reshopper comment")
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <CommentIcon />
      </ListItemIcon>
      Make Reshopper comment
    </MenuItem>
    <EditValueDialogComponent
          isDialogOpen={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onSubmit={action}
          title={"Make Reshopper comment"}
          description={"Enter a comment"}
        />
  </>
}