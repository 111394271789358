import MakeReshopperCommentTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/comment/MakeReshopperCommentTransactionDropdownMenuItem";
import BeginInquiryTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/inquiry/BeginInquiryTransactionDropdownMenuItem";
import MarkAsLostTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/inquiry/MarkAsLostTransactionDropdownMenuItem";
import AddUnexpectedShippingStateTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/AddUnexpectedShippingStateTransactionDropdownMenuItem";
import AdvanceShippingStateTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/AdvanceShippingStateTransactionDropdownMenuItem";
import BookNewLabelTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/BookNewLabelTransactionDropdownMenuItem";
import CustomHandInDeadlineTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/CustomHandInDeadlineTransactionDropdownMenuItem";
import DisableAutoShippingStateUpdatesTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/DisableAutoShippingStateUpdatesTransaction";
import EnableAutoShippingStateUpdatesTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/EnableAutoShippingStateUpdatesTransaction";
import ForceUpdateShippingStatusTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/ForceUpdateShippingStatusTransaction";
import RemoveUnexpectedShippingStateTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/RemoveUnexpectedShippingStateTransaction";
import SwapPrimaryLabelWithLatestAdditionalLabelTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/shipping/SwapPrimaryLabelWithLatestAdditionalLabelTransaction";
import ChangeReadyForPayoutTransactionComponent from "@components/admin/transactions/details/actions/state/ChangeReadyForPayoutTransactionDropdownMenuItem";
import MarkCompleteTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/state/MarkCompleteTransactionDropdownMenuItem";
import MarkItemsAsSoldTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/state/MarkItemsAsSoldTransactionDropdownMenuItem";
import MarkPayedOutTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/state/MarkPayedOutTransactionDropdownMenuItem";
import ReleaseItemsByTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/state/ReleaseItemsByTransactionDropdownMenuItem";
import UnmarkItemsAsSoldTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/state/UnmarkItemsAsSoldTransactionDropdownMenuItem";
import CancelTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/transaction/CancelTransactionDropdownMenuItem";
import DeleteBuyerOnTransactionDropDownMenuItem from "@components/admin/transactions/details/actions/transaction/DeleteBuyerOnTransactionDropDownMenuItem";
import RetractPaymentReportTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/transaction/RetractPaymentReportTransactionDropdownMenuItem";
import ViewDelayedTasksTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/view/ViewDelayedTasksTransactionDropdownMenuItem";
import ViewJsonTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/view/ViewJsonDropdownMenuItemTransactionDropdownMenuItem";
import ViewTrackingJsonTransactionDropdownMenuItem from "@components/admin/transactions/details/actions/view/ViewTrackingJsonTransactionDropdownMenuItem";
import {
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Menu
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { AdminGetTransactionResponse } from "@reshopper/admin-client";
import React, { useState } from "react";

interface Props {
  transaction: AdminGetTransactionResponse;
}

export default function TransactionProfileDropDownActionsComponent(
  props: Props
) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number>();
  function onClose() {
    setAnchorEl(null);
  }

  function handleOpening(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const transaction = props.transaction;
  const id = transaction.id;

  const shouldShowTransitionToLostState =
    transaction.details?.shipping?.status !== undefined &&
    transaction.details.shipping.status !== "LOST";

  const handleClick = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(undefined);
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <>
      <Button onClick={handleOpening}>Actions</Button>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        keepMounted
        onClose={() => onClose()}
      >
        <List style={{ width: "420px" }} disablePadding>
          <ListItem
            button={true}
            key={1}
            onClick={() => {
              handleClick(1);
            }}
          >
            <ListItemText primary="Comment" />
            {1 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={1 === selectedIndex} timeout="auto" unmountOnExit>
            <MakeReshopperCommentTransactionDropdownMenuItem
              id={id}
              onClose={onClose}
            />
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={2}
            onClick={() => {
              handleClick(2);
            }}
          >
            <ListItemText primary="Inquiry" />
            {2 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={2 === selectedIndex} timeout="auto" unmountOnExit>
            {transaction.isShippingTransaction && (
              <BeginInquiryTransactionDropdownMenuItem
                id={id}
                onClose={onClose} 
              />
            )}
            {transaction.bid.payment?.payoutAmountInHundreds &&
              transaction.isShippingTransaction && (
                <MarkAsLostTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={3}
            onClick={() => {
              handleClick(3);
            }}
          >
            <ListItemText primary="Shipping" />
            {3 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={3 === selectedIndex} timeout="auto" unmountOnExit>
            {transaction.isShippingTransaction && (
              <BookNewLabelTransactionDropdownMenuItem
                transactionId={transaction.id}
                transaction={transaction}
                onClose={onClose}
              />
            )}
            {transaction.isShippingTransaction && (
              <ForceUpdateShippingStatusTransactionDropdownMenuItem
                id={id}
                onClose={onClose}
              />
            )}
            {transaction.isShippingTransaction && (
              <SwapPrimaryLabelWithLatestAdditionalLabelTransactionDropdownMenuItem
                id={id}
                onClose={onClose}
              />
            )}
            {transaction.isShippingTransaction &&
              shouldShowTransitionToLostState && (
                <AdvanceShippingStateTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            {transaction.isShippingTransaction &&
              !!transaction.details.shipping
                ?.shippingStateAutoUpdateDisabledAtUtc && (
                <EnableAutoShippingStateUpdatesTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            {transaction.isShippingTransaction &&
              !transaction.details.shipping
                ?.shippingStateAutoUpdateDisabledAtUtc && (
                <DisableAutoShippingStateUpdatesTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            {transaction.isShippingTransaction &&
              !transaction.details.shipping?.inUnexpectedShippingStateAtUtc && (
                <AddUnexpectedShippingStateTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            {transaction.isShippingTransaction &&
              !transaction.details.shipping
                ?.shippingStateAutoUpdateDisabledAtUtc &&
              !!transaction.details.shipping
                ?.inUnexpectedShippingStateAtUtc && (
                <RemoveUnexpectedShippingStateTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              )}
            {transaction.isShippingTransaction &&
              transaction.details.shipping?.handInBeforeUtc && (
                <CustomHandInDeadlineTransactionDropdownMenuItem
                  id={id}
                  date={transaction.details.shipping?.handInBeforeUtc}
                  onClose={onClose}
                />
              )}
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={4}
            onClick={() => {
              handleClick(4);
            }}
          >
            <ListItemText primary="State" />
            {4 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={4 === selectedIndex} timeout="auto" unmountOnExit>
            {transaction.bid.payment && (
              <MarkPayedOutTransactionDropdownMenuItem
                id={id}
                onClose={onClose}
              />
            )}
            {transaction.bid.payment?.readyForPayoutAtUtc && (
              <ChangeReadyForPayoutTransactionComponent
                id={id}
                date={transaction.bid.payment.readyForPayoutAtUtc}
                onClose={onClose}
              />
            )}
            <ReleaseItemsByTransactionDropdownMenuItem
              transaction={transaction}
              onClose={onClose}
            />
            {!transaction.isShippingTransaction && (
              <>
                <MarkCompleteTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
                <UnmarkItemsAsSoldTransactionDropdownMenuItem
                  id={id}
                  onClose={onClose}
                />
              </>
            )}
            {!transaction.isShippingTransaction && transaction.state === 30 && (
              <MarkItemsAsSoldTransactionDropdownMenuItem
                id={id}
                onClose={onClose}
              />
            )}
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={5}
            onClick={() => {
              handleClick(5);
            }}
          >
            <ListItemText primary="Transaction" />
            {5 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={5 === selectedIndex} timeout="auto" unmountOnExit>
            <CancelTransactionDropdownMenuItem id={id} onClose={onClose} />
            <RetractPaymentReportTransactionDropdownMenuItem
              id={id}
              onClose={onClose}
            />
            {transaction.details.shipping === undefined && (
              <DeleteBuyerOnTransactionDropDownMenuItem
                id={id}
                onClose={onClose}
              />
            )}
            <Divider />
          </Collapse>

          <ListItem
            button={true}
            key={6}
            onClick={() => {
              handleClick(6);
            }}
          >
            <ListItemText primary="View" />
            {6 === selectedIndex ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={6 === selectedIndex} timeout="auto" unmountOnExit>
            <ViewDelayedTasksTransactionDropdownMenuItem
              transactionId={id}
              onClose={onClose}
            />
            <ViewJsonTransactionDropdownMenuItem id={id} onClose={onClose} />
            {transaction.details.shipping && (
              <ViewTrackingJsonTransactionDropdownMenuItem
                id={id}
                onClose={onClose}
              />
            )}

            <Divider />
          </Collapse>
        </List>
      </Menu>
    </>
  );
}
