import { adminClient } from "@api/admin/AdminClient";
import { RestError } from "@azure/core-rest-pipeline";
import { createErrorToast } from "@components/ToastContainer";
import { queryApi } from "@hooks/api";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem, Select } from "@material-ui/core";
import LowPriorityIcon from "@material-ui/icons/LowPriority";
import { useState } from "react";

export default function AdvanceShippingStateTransactionComponent(props: {
  id: string,
  onClose: () => void
}): JSX.Element {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [validStates] = queryApi(async (options) =>
    (await adminClient().adminTransactionsValidShippingStatesForTransactionGet(props.id, options)),
    [props.id]);
  const [state, setState] = useState("");

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  function RenderValidStates(): JSX.Element[] {
    if (!validStates) {
      return [<MenuItem key={""} value={""}>Loading</MenuItem>];
    }

    let states = validStates.states.map((state) => (
      <MenuItem key={state} value={state}>
        {state}
      </MenuItem>));

    return states
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setState(event.target.value as string);
  };

  const handleConfirmClicked = async () => {
    try {
      await adminClient().adminTransactionsChangeShippingStatePost(props.id, state);
    } catch (error) {
      const response = error as RestError;
      if ('statusCode' in response && response.statusCode === 400) {
        createErrorToast("Transaction cannot change shipping state: Reepay invoice not found");
      } else {
        createErrorToast("Failed to change shipping state");
      }
    }
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <LowPriorityIcon />
      </ListItemIcon>
      Transition To Shipping State
    </MenuItem>
    <Dialog
      open={dialogOpen}
      onClose={() => {
        setDialogOpen(false)
      }}
      keepMounted
      fullWidth
      aria-labelledby="edit-dialog-slide-title"
      aria-describedby="edit-dialog-slide-description"
    >
      <DialogTitle id="edit-dialog-slide-title">
        Advance shipping state
      </DialogTitle>
      <DialogContent >
        <Select
          autoFocus={true}
          margin="dense"
          value={state}
          label={"Choose new state"}
          fullWidth
          onChange={handleChange}>
          {RenderValidStates()}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            setDialogOpen(false)
          }
          color="default"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => await handleConfirmClicked()}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  </>


}