import { createErrorToast } from "@components/ToastContainer";
import { Button } from "@material-ui/core";
import { AdminGetTransactionResponse } from "@reshopper/admin-client";
import { navigateToExternalUrl } from "@utils/navigation";
import { forwardRef, Ref } from "react";

export const ViewInReepayTransactionButton = forwardRef(
  (
    props: { transaction: AdminGetTransactionResponse },
    _: Ref<HTMLDivElement>
  ) => {
    function handleOpen() {
      if (!props.transaction.bid.payment?.reepayInvoiceHandle) {
        createErrorToast("Reepay for this transaction does not exist");
        return;
      }

      navigateToExternalUrl(
        "https://admin.billwerk.plus/#/rp/payments/invoices/invoice/" +
        props.transaction.bid.payment.reepayInvoiceHandle,
        true
      );
    }

    return (
      <>
        <Button onClick={handleOpen} color="primary">
          Billwerk+
        </Button>
      </>
    );
  }
);

export default ViewInReepayTransactionButton;
