import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { Button } from "@material-ui/core";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function TransitionToDeliveredStateTransactionButton(props: {
  id: string;
}): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
  }

  return (
    <>
      <Button onClick={handleOpen} color="primary">
        Delivered
      </Button>
      <ConfirmationDialogComponent
        isDialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() =>
          performAction(
            () =>
              adminClient().adminTransactionsChangeShippingStatePost(
                props.id,
                "DELIVERED"
              ),
            "Failed to transition to delivered state"
          )
        }
        title="Confirm Transition"
        description="Are you sure you want to transition this transaction to Delivered State?"
      />
    </>
  );
}
