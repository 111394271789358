import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function AddUnexpectedShippingStateTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<HelpIcon />
			</ListItemIcon>
			Add to Unexpected Shipping State
		</MenuItem>
		<ConfirmationDialogComponent
			isDialogOpen={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onConfirm={() => performAction(
				() => adminClient().adminTransactionsInUnexpectedShippingStatePost(props.id, {
					body: {
						isInUnexpectedShippingState: true
					}
				}),
				"Failed to add to unexpected shipping state")}
			title="Confirm adding to unexpected shipping state"
			description="Are you sure you want to add transaction to unexpected shipping state list?"
		/>
	</>
}