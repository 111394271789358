import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function CancelTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <CancelIcon />
      </ListItemIcon>
                  Cancel transaction
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminTransactionsCancelPost(props.id), 
        "Failed to cancel transaction")}
      title="Confirm cancelling transaction"
      description="Are you sure you want to cancel this transaction?"
    />
  </>
}