import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function UnmarkItemsAsSoldTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<MoneyOffIcon />
			</ListItemIcon>
			Unmark items as sold
		</MenuItem>
		<ConfirmationDialogComponent
			isDialogOpen={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onConfirm={() => performAction(
				() => adminClient().adminTransactionsUnmarkItemsAsSoldPost(props.id),
				"Failed to unmark items as sold")}
			title="Confirm unmark items as sold"
			description="Are you sure you want to unmark items as sold?"
		/>
	</>
}