import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import SyncDisabledIcon from "@material-ui/icons/SyncDisabled";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function DisableAutoShippingStateUpdatesTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<SyncDisabledIcon />
			</ListItemIcon>
			Disable automatic shipping updates
		</MenuItem>
		<ConfirmationDialogComponent
			isDialogOpen={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onConfirm={() => performAction(
				() => adminClient().adminTransactionsShippingStateAutoUpdatePost(props.id, {
					body: {
						isDisable: true
					}
				}),
				"Failed to disable automatic shipping updates for transaction")}
			title="Confirm disabling automatic shipping updates"
			description="Are you sure you want to disable automatic shipping updates for transaction?"
		/>
	</>
}