import { adminClient } from "@api/admin/AdminClient";
import { RestError } from "@azure/core-rest-pipeline";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { createErrorToast } from "@components/ToastContainer";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import NotListedLocationIcon from "@material-ui/icons/NotListedLocation";
import { useState } from "react";

export default function MarkAsLostTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  const handleConfirmClicked = async () => {
    try {
      await adminClient().adminTransactionsLostPost(props.id);
    } catch (error) {
      const response = error as RestError;
      if ('statusCode' in response && response.statusCode === 400) {
        createErrorToast("Transaction cannot be marked as lost before it has been payed out");
      } else {
        createErrorToast("Failed to mark transaction as lost");
      }
    }
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <NotListedLocationIcon />
      </ListItemIcon>
      Mark as lost
    </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={async () => await handleConfirmClicked()}
      title="Confirm marking transaction as lost"
      description="Are you sure you want to mark this transaction as lost?"
    />
  </>
}