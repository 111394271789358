import { adminClient } from "@api/admin/AdminClient";
import ImageModalComponent from "@components/admin/items/details/ImageModalComponent";
import InternalLink from "@components/InternalLink";
import WebpImage from "@components/WebpImage";
import { queryApi } from "@hooks/api";
import { Box, Card, CardContent, CircularProgress, Tooltip, Typography } from "@material-ui/core";
import { AdminThreadMessageResponse, AdminThreadParticipantResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useState } from "react";

export function Threads(props: {
    objectId: string
}) {
    const [threads] = queryApi(
        async (options) => await adminClient().adminCommentsThreadMessagesGet(props.objectId, options),
        [props.objectId]);
    if (threads === undefined)
        return <CircularProgress />;

    return <>
        {threads?.map(x => <Thread
            messages={x.messages}
            participants={x.participants}
            key={x.id?.toString()} />)}
    </>;
}

export function Thread(props: {
    style?: React.CSSProperties,
    messages: AdminThreadMessageResponse[],
    participants: AdminThreadParticipantResponse[]
}) {
    return <div style={{
        display: 'flex',
        marginTop: 8,
        ...(props.style || {})
    }}>
        <div style={{
            backgroundColor: "rgba(0,0,0,0.1)",
            width: 2,
            alignSelf: "stretch",
            justifySelf: "stretch",
            marginRight: 16
        }} />
        <div style={{
            flexDirection: "column"
        }}>
            {props.participants &&
                <div style={{
                    marginBottom: 12
                }}>
                    <div style={{
                        fontSize: 12,
                        opacity: 0.5,
                        textTransform: 'uppercase'
                    }}>
                        Conversation with {props.participants.length} participant{props.participants.length === 1 ? "" : "s"}
                    </div>
                    <Participants participants={props.participants} />
                </div>}
            {props.messages.map(m => <Message
                message={m}
                participants={props.participants}
                key={m.id}
            />)}
        </div>
    </div>;
}

function Participants(props: {
    participants: AdminThreadParticipantResponse[]
}) {
    return <div>
        {props.participants.map(p =>
            <Tooltip title={p.name} key={p.id}>
                <WebpImage
                    style={{
                        borderRadius: 1000,
                        marginRight: 2
                    }}
                    size={24}
                    images={[p.image]}
                    href={`/admin/users/details?id=${p.id}`} />
            </Tooltip>)}
    </div>
}

function Content(props: { text: string }) {
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    function handleImageOpen(imageUrl: string | undefined) {
        if (!imageUrl) {
            return;
        }

        setImageUrl(imageUrl);
        setIsImageModalOpen(true);
    }

    if (props.text.endsWith(".webp")) {
        return <>
            <img src={props.text}
                onClick={() => handleImageOpen(props.text)}
                style={{
                    objectFit: "cover",
                    width: "300px",
                    height: "300px"
                }} />
            <ImageModalComponent
                isModalOpen={isImageModalOpen}
                onClose={() => setIsImageModalOpen(false)}
                imageUrl={imageUrl} />
        </>
    } else {
        return <Typography style={{
            fontSize: 16
        }}>
            {props.text}
        </Typography>
    }
}

export function Message(props: {
    message: AdminThreadMessageResponse,
    participants: AdminThreadParticipantResponse[]
}) {
    const participant = props.participants?.find(x => x.id === props.message.userId);
    const message = props.message;

    const participantsThatReadMessage = props.participants?.filter(x =>
        x.lastReadDateUtc &&
        props.message.createdUtc &&
        x.lastReadDateUtc >= props.message.createdUtc);

    return <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        flexWrap="nowrap"
        key={"commentbox" + message.id}
        style={{
            marginBottom: 16
        }}
    >
        <Typography style={{ fontSize: 20 }}>
            {!message.userId ?
                <b>Reshopper</b> :
                <InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(message.userId)} style={{ fontWeight: 'bold', color: 'black' }}>
                    {participant?.name || ""}
                </InternalLink>}
            <span style={{ opacity: 0.5, paddingLeft: 8 }}>
                ({toDanishDateFormat(message.createdUtc)})
            </span>
            {message.deletedUtc && <span style={{ opacity: 0.5, paddingLeft: 8 }}>
                DELETED ({toDanishDateFormat(message.deletedUtc)})
            </span>}
        </Typography>
        <Content text={message.text} />
        <Participants participants={participantsThatReadMessage} />
    </Box >
}

export function CommentsCard(props: {
    style?: React.CSSProperties,
    objectId: string
}) {
    return <Card style={props.style}>
        <CardContent>
            <Typography variant="h4">Comments</Typography>
            <Box borderColor="primary">
                <Threads objectId={props.objectId} />
            </Box>
        </CardContent>
    </Card>
}