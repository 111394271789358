import { adminClient } from "@api/admin/AdminClient";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, ListItemIcon, MenuItem } from "@material-ui/core";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from "@material-ui/pickers";
import { performAction } from "@utils/actions";
import { convertMaterialUiPickersDateToDate } from "@utils/miscellaneous";
import { useState } from "react";

export default function ChangeReadyForPayoutTransactionComponent(props: { id: string, date: Date, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<Date>(props.date);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	function handleDateChange(date: Date | null) {
		if (!date) {
			return;
		}
		setSelectedDate(date);
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<CalendarTodayIcon />
			</ListItemIcon>
			Change date for ready for payout
		</MenuItem>
		<Dialog
			open={dialogOpen}
			onClose={() => setDialogOpen(false)}>
			<DialogTitle
				id="edit-dialog-slide-title">{"Pick new date for paying out transaction"}
			</DialogTitle>
			<DialogContent>
				<DatePicker
					autoOk
					variant="static"
					value={selectedDate}
					onChange={(e) => handleDateChange(convertMaterialUiPickersDateToDate(e))}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() =>
						setDialogOpen(false)
					}
					color="default">
					Cancel
				</Button>
				<Button
					onClick={() => performAction(
						() => adminClient().adminUpdateReadyForPayoutPost(props.id, {
							body: {
								id: props.id,
								date: selectedDate
							}
						}))}
					color="primary">
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	</>
}