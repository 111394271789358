import { adminClient } from "@api/admin/AdminClient";
import { withAdminMenuLayout } from "@components/admin/Decorators";
import NotFoundComponent from "@components/admin/NotFoundComponent";
import TransactionDetailsComponent from "@components/admin/transactions/details/TransactionDetailsComponent";
import { useQueryParameters } from "@hooks/navigation";
import { CircularProgress } from "@material-ui/core";
import { AdminGetTransactionResponse } from "@reshopper/admin-client";
import { useEffect, useState } from "react";

export default withAdminMenuLayout(function TransactionDetailsPageComponent() {
    const props = useQueryParameters<{
        id: string;
    }>();

    const [transaction, setTransaction] = useState<AdminGetTransactionResponse | undefined | null>(undefined);
    useEffect(
        () => {
            const fetchTransaction = async () => {
                try {
                    const transaction = await adminClient().adminTransactionsDetailsJsonGet(props.id);
                    setTransaction(transaction);
                } catch (error) {
                    setTransaction(null);
                }
            };
            fetchTransaction();
        },
        [props.id]);
    if (transaction === undefined) {
        return <CircularProgress />;
    }

    if (transaction === null) {
        return <NotFoundComponent message="Transaction not found" backUrl="/admin/transactions/" />
    }

    return <TransactionDetailsComponent transaction={transaction} />;
});