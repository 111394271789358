import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function MarkPayedOutTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<AccountBalanceWalletIcon />
			</ListItemIcon>
			Mark as payed out
		</MenuItem>
		<ConfirmationDialogComponent
			isDialogOpen={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onConfirm={() => performAction(
				() => adminClient().adminTransactionsMarkPayedOutPost(props.id),
				"Failed to mark as payed out")}
			title="Confirm mark as payed out"
			description="Are you sure you want to mark as payed out?"
		/>
	</>
}