import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function DeleteBuyerOnTransactionDropDownMenuItemComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
      Remove buyer from transaction
    </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminTransactionRemoveBuyerPut(props.id),
        "Failed to delete buyer on transaction")}
      title="Confirm deleting buyer on transaction"
      description="Are you sure you want to delete the buyer on this transaction?"
    />
  </>
}