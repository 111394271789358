import AdminInteractionDialog from "@components/admin/AdminInteractionDialog";
import { CommentsCard } from "@components/admin/comments/Comment";
import CopyToClipboard from "@components/admin/CopyToClipboard";
import ImageModalComponent from "@components/admin/items/details/ImageModalComponent";
import PageTitle from "@components/admin/PageTitle";
import EditAdminNoteTransactionDropdownMenuItem from "@components/admin/transactions/details/EditAdminNoteTransactionDropdownMenuItem";
import TransactionAdditionalLabelBookingsComponent from "@components/admin/transactions/details/TransactionAdditionalLabelBookingsComponent";
import TransactionProfileDropDownActionsComponent from "@components/admin/transactions/details/TransactionProfileDropDownActionsComponent";
import TransitionToDeliveredStateTransactionDropdownMenuItem from "@components/admin/transactions/details/TransitionToDeliveredStateTransactionDropdownMenuItem";
import ViewInReepayTransactionDropdownMenuItem from "@components/admin/transactions/details/ViewInReepayTransactionDropdownMenuItem";
import InternalLink from "@components/InternalLink";
import WebpImage from "@components/WebpImage";
import {
	Box, ButtonGroup, Card,
	CardContent,
	Divider,
	Link,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	useMediaQuery
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import { AdminGetTransactionPaymentResponse, AdminGetTransactionResponse, AdminGetTransactionUserResponse } from "@reshopper/admin-client";
import { addPlusIfPositiveNumber, formatAdminAddress, formatTransactionsState, toDanishPriceFormat } from "@utils/formatting";
import { pickFirstLargestImage } from "@utils/images";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { navigateToExternalUrl } from "@utils/navigation";
import { ReactNode, useState } from "react";

const useStyles = makeStyles(() =>
	createStyles({
		TableCell: {
			borderBottom: "none",
			paddingTop: "4px",
			paddingBottom: "4px",
			paddingLeft: 0
		},
		TopCell: {
			paddingTop: "0",
			borderBottom: "none",
		},
		SmallIcon: {
			verticalAlign: "middle",
			paddingBottom: "4px",
		},
		TypographyFontSize: {
			fontSize: "0.875rem",
		},
		button: {
			height: 20,
			marginLeft: 10
		},
		container: {
			display: "inline-block",
		}
	}),
);

interface TableForTransactionDetailsPageProps {
	transaction: AdminGetTransactionResponse;
}

function Row(props: { title: string, content: ReactNode }) {
	const classes = useStyles();
	return <TableRow>
		<TableCell className={classes.TableCell}>
			<b>{props.title}</b>
		</TableCell>
		<TableCell className={classes.TableCell}>
			{props.content || "-"}
		</TableCell>
	</TableRow>;
}

function TableForTransactionDetailsPage(props: TableForTransactionDetailsPageProps): JSX.Element {
	const print = useMediaQuery('print');
	const tableBody = props.transaction.isShippingTransaction ?
		renderShippingTransaction(props, print) :
		renderPickupTransaction(props, print);

	return <Table size="small" style={{
		width: "auto"
	}}>
		<TableBody>
			{tableBody}
		</TableBody>
	</Table>
}

function renderFeeRow(props: TableForTransactionDetailsPageProps): React.ReactNode {
	let payment = props.transaction.bid.payment;
	if (!payment)
		return null;

	return <Row
		title={"Fee (" + payment.sellerProtectionFee?.feeModifier + "%)"}
		content={payment.sellerProtectionFee?.totalFeeAmountInHundreds ? toDanishPriceFormat(payment.sellerProtectionFee?.totalFeeAmountInHundreds / 100) : toDanishPriceFormat(0)}
	/>
}

function renderDate(date: Date, title: string) {
	return <Row
		title={title}
		content={date &&
			toDanishDateFormat(date)}
	/>
}

function renderReadyForPayout(props: TableForTransactionDetailsPageProps): React.ReactNode {
	const readyForPayoutAtUtc = props.transaction.bid.payment?.readyForPayoutAtUtc;
	if (!readyForPayoutAtUtc) {
		return null;
	}

	return <Row
		title="Ready for payout at"
		content={<>
			{toDanishDateFormat(readyForPayoutAtUtc)}
		</>}
	/>
}

function renderPayout(props: TableForTransactionDetailsPageProps): React.ReactNode {
	const paymentDetails = props.transaction.bid.payment;
	const payout = paymentDetails?.payoutAmountInHundreds;
	if (!paymentDetails || !payout) {
		return null;
	}

	return <Row
		title="Payout to seller"
		content={<>
			{toDanishPriceFormat(payout / 100)}
			{generatePayoutString(paymentDetails)}
		</>}
	/>
}

function generatePayoutString(paymentDetails: AdminGetTransactionPaymentResponse): string {
	const payoutAtUtc = paymentDetails?.payoutAtUtc;
	const payoutToAccountNumber = paymentDetails?.payedOutToAccountNumber;
	let payoutString = "-";

	if (!!payoutAtUtc) {
		payoutString = " at " + toDanishDateFormat(payoutAtUtc);
		if (!!payoutToAccountNumber) {
			payoutString += " to " + `${payoutToAccountNumber?.slice(0, 4)}-${payoutToAccountNumber?.slice(4)}`;
		} else {
			payoutString += " to unknown account";
		}
	} else {
		payoutString = " not payed out";
	}

	return payoutString;
}

function renderUser(user: AdminGetTransactionUserResponse | undefined, title: string): React.ReactNode {
	if (!user) {
		return <Row
			title={title}
			content={"No user"}
		/>;
	}

	let monitoredAndBlockedStatus = "";
	if (user.isMonitored) {
		monitoredAndBlockedStatus = " · Monitored";
	}
	if (user.blockedUtc) {
		monitoredAndBlockedStatus = " · Blocked";
	}
	return <>
		<Row
			title={title}
			content={<>
				<InternalLink href={"/admin/users/details/?id=" + encodeURIComponent(user.id)}>
					{user.publicName ?? "<Deleted>"}
				</InternalLink>, {formatAdminAddress(user.address) ?? "No address"}
			</>}
		/>
		<Row
			title=""
			content={
				<>
					<Typography
						variant="body2"
						display="inline"
						style={{ fontStyle: "italic" }}
					>
						{"Member since "}
						{toDanishDateFormat(user.createdUtc)}
						{" · "}
						{user.soldItemCount}
						{" sales · "}
						{user.purchasedItemCount}
						{" purchases · "}
						{user.averageRating ?
							(" " + addPlusIfPositiveNumber(parseFloat(user.averageRating.toFixed(2)))) :
							" No ratings"}
					</Typography>
					<Typography
						variant="body2"
						display="inline"
						style={{ fontStyle: "italic" }}
						color="error"
					>
						{monitoredAndBlockedStatus}
					</Typography>
				</>}
		/>
	</>
}

function renderCurrentSellerAccountNumber(props: TableForTransactionDetailsPageProps) {
	const sellerAccountNumber = props.transaction.bid.payment?.currentSellerAccountNumber;

	return <Row
		title="Seller Account Number"
		content={!!sellerAccountNumber ? sellerAccountNumber : "-"}
	/>
}

function renderTransactionState(props: TableForTransactionDetailsPageProps) {
	return <Row
		title="State"
		content={formatTransactionsState(props.transaction.state)}
	/>
}

function renderAdminNote(adminNote?: string) {
	return <Row
		title="Admin Note"
		content={adminNote}
	/>
}

function renderShippingTransaction(
	props: TableForTransactionDetailsPageProps,
	print: boolean): JSX.Element {
	let transaction = props.transaction;
	let shipping = transaction.details.shipping;
	let payment = transaction.bid.payment;
	return <>
		{transaction.state !== 20 && renderTransactionState(props)}
		{renderShippingState()}
		{renderPackageLabel()}
		{renderTrackingLink()}
		{renderUser(transaction.seller, "Seller")}
		{!print && renderCurrentSellerAccountNumber(props)}
		{renderUser(transaction.buyer, "Buyer")}
		{renderDate(transaction.createdUtc, "Created Date")}
		{transaction.ttlUtc &&
			renderDate(transaction.ttlUtc, "TTL Date")}
		{transaction.acceptedUtc &&
			renderDate(transaction.acceptedUtc, "Accept Date")}
		{shipping?.handInBeforeUtc &&
			renderDate(shipping?.handInBeforeUtc, "Hand in before")}
		{shipping?.shippedAtUtc &&
			renderDate(shipping?.shippedAtUtc, "Handed in date")}
		{renderPickupPoint()}
		{shipping?.pendingPickupAtUtc &&
			renderDate(shipping.pendingPickupAtUtc, "Ready for pickup date")}
		{shipping?.pickedUpAtUtc &&
			renderDate(shipping.pickedUpAtUtc, "Pickup date")}
		{shipping?.lastInquiryUtc &&
			renderDate(shipping.lastInquiryUtc, "Inquired at date")}
		{payment?.payoutAtUtc &&
			renderDate(payment?.payoutAtUtc, "Payed out at")}
		{renderTotal(props)}
		{!print && renderShippingAmount()}
		{(!print &&
			props.transaction.acceptedUtc) &&
			renderFeeRow(props)}
		{!print && renderReadyForPayout(props)}
		{!print && renderPayout(props)}
		{renderAdminNote(props.transaction.adminNote)}
	</>;

	function renderTrackingLink() {
		let transactionLabelBooking = props.transaction.details?.shipping?.transactionLabelBooking;
		if (!transactionLabelBooking) {
			return null;
		}

		return <Row
			title="Tracking link"
			content={<>
				{transactionLabelBooking.packageNumber + " - "}
				{transactionLabelBooking.shipmentNumber && transactionLabelBooking.shipmentNumber + " - "}
				<InternalLink
					onClick={() => navigateToExternalUrl(transactionLabelBooking!.trackingUrl, true)}
				>
					Open Tracking
				</InternalLink>

				{
					transactionLabelBooking.receiverTrackingLink &&
					<InternalLink
						onClick={() => navigateToExternalUrl(transactionLabelBooking!.receiverTrackingLink!, true)}
					>
						{" · ReceiverTrackingLink"}
					</InternalLink>
				}
				{
					transactionLabelBooking.senderTrackingLink &&
					<InternalLink
						onClick={() => navigateToExternalUrl(transactionLabelBooking!.senderTrackingLink!, true)}
					>

						{" · SenderTrackingLink"}
					</InternalLink>
				}

			</>} />
	}

	function renderPickupPoint() {
		const pickupPoint = props.transaction.details?.shipping?.pickupPoint;
		const address = pickupPoint &&
			(pickupPoint.street + ", " +
				pickupPoint.postalCode + ", " +
				pickupPoint.city);

		const classes = useStyles();
		return <TableRow>
			<TableCell className={classes.TableCell}>
				<CopyToClipboard tooltipProps={{ title: "Copied Pickup Point ID!", leaveDelay: 1000 }}>
					{(copy) => (
						<b onClick={() => copy(pickupPoint?.id)}>
							Pickup Point
						</b>
					)}
				</CopyToClipboard>
			</TableCell>
			<TableCell className={classes.TableCell}>
				{address && <>
					<Link
						target="_blank"
						rel="noreferrer"
						href={`https://www.google.com/maps/place/${encodeURIComponent(address)}`}>
						{pickupPoint?.name},
						{address}
					</Link>
					(id. {pickupPoint?.id})
				</>}
			</TableCell>
		</TableRow>;
	}

	function renderShippingAmount(): React.ReactNode {
		const shippingCosts = props.transaction.bid.payment?.shippingAmountInHundreds;
		if (shippingCosts === undefined)
			return null;

		return <Row
			title="Shipping"
			content={toDanishPriceFormat(shippingCosts / 100)}
		/>
	}

	function renderShippingState() {
		return <Row
			title="Shipping State"
			content={props.transaction.details?.shipping?.status}
		/>
	}

	function renderPackageLabel() {
		let shippingDetails = props.transaction.details?.shipping;
		if (!shippingDetails) {
			throw new Error("Shipping details not found.");
		}

		let packageLabel = shippingDetails?.transactionLabelBooking?.packageLabel ?? "-";
		return <Row
			title="Package label"
			content={packageLabel + " / (" + shippingDetails.shippingProviderType + " " + shippingDetails.weight + "kg)"}
		/>
	}
}

function renderPickupTransaction(
	props: TableForTransactionDetailsPageProps,
	print: boolean): JSX.Element {
	let transaction = props.transaction;
	return <>
		{renderTransactionState(props)}
		{renderUser(transaction.seller, "Seller")}
		{!print && renderCurrentSellerAccountNumber(props)}
		{renderUser(transaction.buyer, "Buyer")}
		{renderDate(transaction.createdUtc, "Created Date")}
		{transaction.acceptedUtc &&
			renderDate(transaction.acceptedUtc, "Accept Date")}
		{renderTotal(props)}
		{(!print &&
			transaction.isSellerBusinessUser &&
			transaction.bid.payment &&
			transaction.acceptedUtc)
			&& renderFeeRow(props)}
		{!print && renderReadyForPayout(props)}
		{(!print &&
			transaction.bid.payment &&
			transaction.acceptedUtc)
			&& renderPayout(props)}
		{renderAdminNote(props.transaction.adminNote)}
	</>
}

function renderTotal(props: TableForTransactionDetailsPageProps) {
	const amount = props.transaction.bid.totalAmountForTransactionInHundreds;
	return <Row
		title={props.transaction.acceptedUtc ?
			"Total" :
			"Current bid"}
		content={<>
			{toDanishPriceFormat(amount / 100)}
		</>}
	/>
}

function InsertSkeleton(): JSX.Element {

	function tableSkeleton(): JSX.Element[] {
		let elements = []
		for (let index = 0; index < 8; index++) {
			elements.push(
				<TableRow key={"skeleton" + index}>
					<Skeleton height={28} width={547} />
				</TableRow>
			)
		}
		return elements;
	}

	return <>
		<Skeleton height={48} />
		<Divider />
		<Box display="flex" flexDirection="row">
			<Box flexGrow={1}>
				<Skeleton variant="rect" height={200} width={200} />
			</Box>
			<Table size="small">
				{tableSkeleton()}
			</Table>
		</Box>
	</>
}

function TransactionItems(props: { transaction: AdminGetTransactionResponse }) {
	const regularImageSize = 150;

	return <Box style={{
		lineHeight: 0
	}}>
		{props.transaction.items
			.map((item, index) => <Card
				onClick={() => window.location.href = "/admin/items/details/?id=" + encodeURIComponent(item.itemId)}
				style={{
					display: 'inline-block',
					cursor: 'pointer',
					marginRight: 16,
					width: regularImageSize + 32
				}}
				key={item.itemId}
			>
				<CardContent>
					<WebpImage
						images={[item.image]}
						size={regularImageSize}
					/>
					<Typography><b>{item.brandOrTitle} {item.description}</b><br />{item.size}</Typography>
				</CardContent>
			</Card>)}
	</Box>
}

export const TransactionDetailsComponent = (props: {
	transaction: AdminGetTransactionResponse;
}) => {
	const [imageUrl, setImageUrl] = useState("");
	const [isImageModalOpen, setIsImageModalOpen] = useState(false);

	const print = useMediaQuery('print');

	function handleImageOpen(imageUrl?: string) {
		if (!imageUrl)
			return;

		setImageUrl(imageUrl);
		setIsImageModalOpen(true);
	}

	function renderInUnexpectedShippingState(inUnexpectedShippingStateAtUtc: Date | undefined) {
		if (inUnexpectedShippingStateAtUtc) {
			return <Box
				marginLeft={2}
			>
				<Typography
					color="error"
					variant="h4"
				>
					(Unexpected shipping state)
				</Typography>
			</Box>
		}
	}

	function renderInAutoUpdatesDisabled(shippingStateAutoUpdateDisabledAtUtc: Date | undefined) {
		if (shippingStateAutoUpdateDisabledAtUtc) {
			return <Box
				marginLeft={2}
			>
				<Typography
					color="error"
					variant="h4"
				>
					(Auto updates disabled)
				</Typography>
			</Box>
		}
	}

	if (!props?.transaction?.items) {
		return <InsertSkeleton />
	}

	let shippingDetails = props.transaction.details?.shipping;
	let transactionLabelBooking = shippingDetails?.transactionLabelBooking;

	const barcodeUrl = transactionLabelBooking
		&& `https://barcode.tec-it.com/barcode.ashx?data=${transactionLabelBooking.packageNumber}&code=Code128&dpi=500&dataseparator=&download=false`;

	const packageImageSize = 450;
	const packageImages = shippingDetails?.packageImages;
	const packageImage = packageImages && pickFirstLargestImage(packageImages);
	const transaction = props.transaction;
	const id = transaction.id;

	function showDeliveredButtonsIfShippingStateAllows(state: string): boolean {
		switch (state) {
			case "DELIVERED":
				return false;
			case "RETURN":
				return false;
			case "LOST":
				return false;
			default:
				return true;
		}
	}

	return (<>
		<PageTitle title={
			<Box
				display="flex"
			>
				{props.transaction.shortId}
				{renderInUnexpectedShippingState(props.transaction.details.shipping?.inUnexpectedShippingStateAtUtc)}
				{renderInAutoUpdatesDisabled(props.transaction.details.shipping?.shippingStateAutoUpdateDisabledAtUtc)}
			</Box>}>
			<ButtonGroup >
				<EditAdminNoteTransactionDropdownMenuItem
					id={id}
					adminNote={transaction.adminNote} />
				{transaction.isShippingTransaction && showDeliveredButtonsIfShippingStateAllows(transaction.details.shipping?.status!) &&
					<TransitionToDeliveredStateTransactionDropdownMenuItem
						id={id} />}
				{transaction.bid.payment?.reepayInvoiceHandle &&
					<ViewInReepayTransactionDropdownMenuItem
						transaction={transaction} />}
				{!print && <TransactionProfileDropDownActionsComponent transaction={props.transaction} />}
			</ButtonGroup>

		</PageTitle>
		<Card style={{ marginBottom: 16 }}>
			<CardContent>
				<Box display="flex" flexDirection="row" justifyContent="space-between">
					<Box>
						<TableForTransactionDetailsPage transaction={props.transaction} />
					</Box>
					<Box>
						{packageImage &&
							<WebpImage
								images={packageImages}
								size={packageImageSize}
								onClick={() => handleImageOpen(packageImage.url)}
							/>}
						{!print && barcodeUrl &&
							<a href={barcodeUrl} target="_blank" rel="noopener noreferrer" style={{
								marginTop: 4,
								marginBottom: 16,
								display: 'block'
							}}>
								<img src={barcodeUrl} alt="Barcode for scanning" style={{
									width: packageImageSize
								}} />
							</a>}
					</Box>
				</Box>
			</CardContent>
		</Card>
		<TransactionItems transaction={props.transaction} />
		{shippingDetails?.additionalTransactionLabelBookings?.length !== 0 &&
			<TransactionAdditionalLabelBookingsComponent transaction={props.transaction} />}
		{!print && <CommentsCard objectId={props.transaction.id} style={{ marginTop: 16 }} />}
		<ImageModalComponent
			isModalOpen={isImageModalOpen}
			onClose={() => setIsImageModalOpen(false)}
			imageUrl={imageUrl} />
		{transaction.details.shipping && <AdminInteractionDialog
			objectId={transaction.id}
			adminInteractionObjectType="transaction"
			transactionShippingProviderType={transaction.details.shipping?.shippingProviderType}
		/>}
	</>);
}

export default TransactionDetailsComponent;