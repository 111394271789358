import DelayedTasksListComponent from "@components/admin/delayed-tasks/DelayedTasksListComponent";
import { Dialog, DialogContent, DialogTitle, ListItemIcon, MenuItem } from "@material-ui/core";
import AlarmIcon from '@material-ui/icons/Alarm';
import { useState } from "react";

export default function ViewDelayedTasksDropdownMenuItemTransactionComponent(props: { transactionId: string, onClose: () => void }): JSX.Element {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	async function handleOpen() {
		props.onClose();
		setIsDialogOpen(true);
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<AlarmIcon />
			</ListItemIcon>
			View Delayed Tasks
		</MenuItem>
		<Dialog
			open={isDialogOpen}
			onClose={() => setIsDialogOpen(false)}
			fullWidth
			maxWidth="xl"
		>
			<DialogTitle>
				Delayed Tasks
			</DialogTitle>
			<DialogContent
				style={{ marginBottom: 20 }}
			>
				<DelayedTasksListComponent entityId={props.transactionId} />
			</DialogContent>
		</Dialog>
	</>
}
