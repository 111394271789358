import { adminClient } from "@api/admin/AdminClient";
import JsonModalTransactionComponent from "@components/admin/transactions/details/actions/view/JsonModalTransactionDropdownMenuItem";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { useState } from "react";

export default function ViewJsonDropdownMenuItemTransactionComponent(props: {id: string, onClose: () => void}): JSX.Element {
  const [transactionJson, setTransactionJson] = useState<string>()

  async function handleOpen() {
    props.onClose();
    
    const response = await adminClient().adminTransactionsTransactionAsJsonGet(props.id);
    setTransactionJson(response.result);
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <ClearAllIcon />
      </ListItemIcon>
      View JSON
    </MenuItem>
    <JsonModalTransactionComponent
      isModalOpen={!!transactionJson}
      closeModal={() => setTransactionJson(undefined)}
      json={transactionJson || ""}
		title="Transaction JSON"
    />
  </>
}
