import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function SwapPrimaryLabelWithLatestAdditionalLabelTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <SwapVertIcon />
      </ListItemIcon>
                  Swap primary label with latest additional label
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminTransactionsSwapShippingLabelsPost(props.id), 
        "Failed to swap primary label with latest additional label")}
      title="Confirm swapping primary label with latest additional label"
      description="Are you sure you want to swap primary label with latest additional label?"
    />
  </>
}