import { adminClient } from "@api/admin/AdminClient";
import { RestError } from "@azure/core-rest-pipeline";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { createErrorToast } from "@components/ToastContainer";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import { useState } from "react";

export default function ForceUpdateShippingStatusTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  const handleConfirmClicked = async () => {
    try {
      await adminClient().adminTransactionsForceUpdateShippingStatusPost(props.id);
    } catch (error) {
      const response = error as RestError;
      if ('statusCode' in response && response.statusCode === 400) {
        createErrorToast("Transaction cannot change shipping state: Reepay invoice not found");
      } else {
        createErrorToast("Failed to force update shipping state");
      }
    }
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <FlipCameraAndroidIcon />
      </ListItemIcon>
                  Update shipping status
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={async () => await handleConfirmClicked()}
      title="Confirm force updating shipping status"
      description="Are you sure you want to force update shipping status?"
    />
  </>
}