import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function MarkCompleteTransactionComponent(props: { id: string, onClose: () => void }): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleOpen() {
    setDialogOpen(true);
    props.onClose();
  }

  return <>
    <MenuItem button
      onClick={handleOpen}
    >
      <ListItemIcon>
        <CheckCircleIcon />
      </ListItemIcon>
                  Mark Complete
              </MenuItem>
    <ConfirmationDialogComponent
      isDialogOpen={dialogOpen}
      onClose={() => setDialogOpen(false)}
      onConfirm={() => performAction(
        () => adminClient().adminTransactionsMarkCompletePost(props.id), 
        "Failed to mark transaction complete")}
      title="Confirm Mark Complete"
      description="Are you sure you want to mark transaction complete?"
    />
  </>
}