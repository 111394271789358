import { adminClient } from "@api/admin/AdminClient";
import ConfirmationDialogComponent from "@components/admin/ConfirmationDialogComponent";
import { ListItemIcon, MenuItem } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AdminGetTransactionResponse } from "@reshopper/admin-client";
import { performAction } from "@utils/actions";
import { useState } from "react";

export default function ReleaseItemsByTransactionComponent(props: { transaction: AdminGetTransactionResponse, onClose: () => void }): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}

	function generateActionDescription() {
		let actionDescription = "";
		if (props.transaction.state !== 40 && props.transaction.state !== 1 && props.transaction.state !== 30) {
			actionDescription = "TRANSACTION IS ONGOING - ";
		} else {
			actionDescription = "TRANSACTION IS DECLINED/COMPLETED/CANCELLED - ";
		}
		actionDescription += "Are you sure you want to release the items from transaction? - (This will unreserve and unmark items as sold)"
		return actionDescription;
	}

	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<HighlightOffIcon />
			</ListItemIcon>
			Release Items From Transaction
		</MenuItem>
		<ConfirmationDialogComponent
			isDialogOpen={dialogOpen}
			onClose={() => setDialogOpen(false)}
			onConfirm={() => performAction(() => adminClient().adminTransactionsUnmarkItemsAsSoldPost(props.transaction.id), "Failed to release item.")}
			title="Confirm releasing items from transaction."
			description={generateActionDescription()}
		/>
	</>
}
