import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import copy from "clipboard-copy";
import { useState } from "react";

interface Props {
  tooltipProps?: Partial<TooltipProps>;
  children: (onCopy: (content: any) => void) => React.ReactElement<any>;
}

/**
 * Render prop component that wraps element in a Tooltip that shows "Copied to clipboard!" when the
 * onCopy function is invoked
 */
export default function CopyToClipboard(props: Props) {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const onCopy = (content: any) => {
    copy(content);
    setIsTooltipOpen(true);
  };

  const onTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  return (
    <Tooltip
      open={isTooltipOpen}
      title={"Copied to clipboard!"}
      leaveDelay={1500}
      onClose={onTooltipClose}
      {...props.tooltipProps || {}}
    >
      {props.children(onCopy) as React.ReactElement<any>}
    </Tooltip>
  );
}
